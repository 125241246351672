/*------------------------------------*\
    accommodation.CSS
\*------------------------------------*/

/**
 * Import Variables
 */

@import "toolkit/vars"; //Custom vars that override bootstrap vars
@import "toolkit/foundations/tk-icons"; //we need to extend icons for modal
@import "accommodation/vr-aframe"; //360 video and image aframe pulled into lightbox iframe
@import "accommodation/residence-map"; //Custom google maps
@import "accommodation/lightbox"; //Image gallery lightbox
@import "accommodation/icon-list"; // List of inline icons
@import "accommodation/card-animation"; // Card filter animation


//temporary style to invert the image icons to white on the single residence slider - DELETE this class after the images have been converted to white
.inverted {
    filter: invert(100%);
    -webkit-filter: invert(1);
}

/* Accom iframe */

.map-frame {
    width: 100%;
    height: 500px;
    outline: 0;
}

.heading-acc-rooms {
	text-align: center;
	font-family: $font-family-serif;
	line-height: 150%;
}

/* CUSTOM STUFF HERE! */

@media( min-width: 480px ) {
	.table-heading {
		min-width: 180px;
	}
}
.ms-parent {
	max-width: 100%;
}

@media( min-width: 768px ) {

    .table-w-10 {
        width: 10%;
    }

    .table-w-15 {
        width: 15%;
    }

	.table-w-20 {
		width: 20%;
    }

    .table-w-25 {
        width: 25%;
    }

    .table-w-30 {
        width: 30%;
    }

    .table-w-40 {
        width: 40%;
    }

}

/**
 * Swiper Multiple items (see Accommodation: Single residence page)
 */

 .swiper-multi {
    background: $color-masthead-light;
    padding: 0 45px;
    overflow: initial;
    .owl-item {
        text-align: center;
        p {
            max-width: 265px;
            margin: 0 auto;
            font-size: 16px;
            color: $color-white; //margin-top: 10px;
            padding: 0px 10px 20px 10px;
        }
        .item {
            padding: 5px;
        }
        .tk-icon {
            color: white;
            font-size: 60px;
            margin-top: $space-sm;
            display: block;
        }
    }
    .owl-buttons {
        div {
            top: 25px;
        }
        .owl-prev {
            border: 0;
            height: 100%;
            left: 0;
            top: 0;
            @media (min-width: 1340px) {
                top: 25px;
            }
        }
        .owl-next {
            border: 0;
            height: 100%;
            right: 0;
            top: 0;
            @media (min-width: 1340px) {
                top: 25px;
            }
        }
    }
}

