/* Card filter animation -> residence index

*/


/**
 * Animation of residence cards
 */

 .card-anim {
    transform: translateX(-20px);
    opacity: 0;

    .no-js & {
        transform: translateX(0px);
        opacity: 1;
    }
}

[class*='animate-in'] {
    transform: translateX(0px);
    transition-property: opacity, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    opacity: 1;
}

.animate-in-1 {
    transition-delay: 0s;
}
.animate-in-2 {
    transition-delay: 0.1s;
}
.animate-in-3 {
    transition-delay: 0.2s;
}
.animate-in-4 {
    transition-delay: 0.3s;
}
.animate-in-5 {
    transition-delay: 0.4s;
}
.animate-in-6 {
    transition-delay: 0.5s;
}



