/**
 *  Residence Map
 */

.marker {
    cursor: pointer;
    width: auto !important;
}

.marker-toggle {
    position: absolute;
    bottom: 0;
    left: 10px;
    button {
        text-transform: uppercase;
        color: $color-white;
        border: none;
        padding: 10px;
        margin-right: 5px;
        background: $color-masthead;

        &.active {
            background: $color-brand-1;
        }
    }
}

.residence-map {
    position: relative;
    .googlemap {
        width: 100%;
        height: 300px;
        > div {
            position: absolute;
            top: 0;
        }
        h3 {
            margin: 0;
            color: $color-brand-2;
        }
        * {
            box-shadow: none !important;
            font-size: 12px;
        }
        .marker-text {
            padding: 1px; //fixes the width bug
            p {
                margin: 0;
            }
        }
        .close-infowindow {
            position: relative;
            right: 9px;
            top: 5px;
            cursor: pointer;
            color: $color-white;
            z-index: 1;
            border: 0;
            text-align: right;
            width: 27px;
            float: right;
        }
        @media (min-width: $screen-xs-min) {
            height: 350px;
        }
        @media (min-width: $screen-sm-min) {
            height: 450px;
        }
    }
}