/* Icon list -> single residence page

*/

.icon-list {	
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: top;
	justify-content: space-around; 
}

.icon-list-item {	
	text-align: center;
	width: 120px;
	
	@media (min-width: $screen-sm-min) {
		flex: 1;
	}	

	.tk-icon {
		font-size: 48px;
		@media (min-width: $screen-sm-min) {
			font-size: 60px;
		}
		color: $color-brand-2;		
	}
}