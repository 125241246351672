/*------------------------------------*    accommodation.CSS
\*------------------------------------*/
/**
 * Import Variables
 */
/*------------------------------------*   $CUSTOM TOOLKIT VARIABLES
\*------------------------------------*/
/**
 * Place any custom toolkit variables here.
 * N.B. dont use sass darken or lighten as we
 * find and replace colors HEX's for theming
 */
/* For quick reference breakpoints
 * $screen-xs:                  480px;
 * $screen-sm:                  768px;
 * $screen-md:                  1024px;
 * $screen-lg:                  1260px;
 */
/**
 * UI colours
 */
/**
 * Layout
 * Wrappers & widths
 */
/**
 * Bootstrap Mid-Small - col-ms-* - the missing grid set for Bootstrap3.
 * This is a hack to fill the gap between 480 and 767 pixels. See _boostrap-ms.scss
 */
/**
 * Screen xxs max, do queries don't overlap
 */
/**
 * Old Engineering wrapper, just for reference
 * $wrapper-xs: 660px;
 * $wrapper-sm: 768px;
 * $wrapper-md: 992px;
 * $wrapper-lg: 1200px;
 * $wrapper-xl: 1260px;
 * xs: 660px;
 * sm: 768px;
 * md: 992px;
 * lg: 1260px;
 */
/**
 * Spacing
 */
/**
 * REM spacing
 * Not currently in use, start using when ready
 */
/**
 * Typography
 */
/*------------------------------------*   $BOOTSTRAP OVERRIDEN VARIABLES
\*------------------------------------*/
/**
 * Overide the default bootsrap variables here
 */
/**
 * Icon font CSS generated by gulp see templates -> _tk-icons.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */
/**
 * Icon font CSS generated by gulp see templates -> _tk-icons-mixins.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */
/**
 * Icon font mixins
 */
@font-face {
  font-family: "tk-icons";
  src: url("../fonts/tk-icons.eot");
  src: url("../fonts/tk-icons.eot?#iefix") format("eot"), url("../fonts/tk-icons.woff2") format("woff2"), url("../fonts/tk-icons.woff") format("woff"), url("../fonts/tk-icons.ttf") format("truetype"), url("../fonts/tk-icons.svg#tk-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.tk-icon, [class*="lightbox-view-"] .rs-img-2-1:before,
[class*="tk-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "tk-icons" !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  transform: translate(0px, -1px);
  line-height: 1;
  font-size: inherit;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tk-icon-16 {
  font-size: 16px; }

.tk-icon-24 {
  font-size: 24px; }

.tk-icon-32 {
  font-size: 32px; }

.tk-icon-48 {
  font-size: 48px; }

/**
 * Icon font class names
 */
.tk-icon-add:before {
  content: "\EA01"; }

.tk-icon-alert-danger:before {
  content: "\EA02"; }

.tk-icon-alert-info:before {
  content: "\EA03"; }

.tk-icon-alert-success:before {
  content: "\EA04"; }

.tk-icon-alert-warning:before {
  content: "\EA05"; }

.tk-icon-calendar:before {
  content: "\EA06"; }

.tk-icon-campus-map:before {
  content: "\EA07"; }

.tk-icon-chevron-down:before {
  content: "\EA08"; }

.tk-icon-chevron-left:before {
  content: "\EA09"; }

.tk-icon-chevron-right:before {
  content: "\EA0A"; }

.tk-icon-chevron-up:before {
  content: "\EA0B"; }

.tk-icon-close:before {
  content: "\EA0C"; }

.tk-icon-download:before {
  content: "\EA0D"; }

.tk-icon-external:before {
  content: "\EA0E"; }

.tk-icon-home:before {
  content: "\EA0F"; }

.tk-icon-letter:before {
  content: "\EA10"; }

.tk-icon-mail:before {
  content: "\EA11"; }

.tk-icon-marker:before {
  content: "\EA12"; }

.tk-icon-navicon:before {
  content: "\EA13"; }

.tk-icon-news:before {
  content: "\EA14"; }

.tk-icon-phone:before {
  content: "\EA15"; }

.tk-icon-print:before {
  content: "\EA16"; }

.tk-icon-profile:before {
  content: "\EA17"; }

.tk-icon-remove:before {
  content: "\EA18"; }

.tk-icon-rss:before {
  content: "\EA19"; }

.tk-icon-search:before {
  content: "\EA1A"; }

.tk-icon-share:before {
  content: "\EA1B"; }

.tk-icon-social-facebook:before {
  content: "\EA1C"; }

.tk-icon-social-google:before {
  content: "\EA1D"; }

.tk-icon-social-instagram:before {
  content: "\EA1E"; }

.tk-icon-social-linkedin:before {
  content: "\EA1F"; }

.tk-icon-social-twitter:before {
  content: "\EA20"; }

.tk-icon-social-weibo:before {
  content: "\EA21"; }

.tk-icon-social-youtube:before {
  content: "\EA22"; }

.tk-icon-sort:before {
  content: "\EA23"; }

.tk-icon-star:before {
  content: "\EA24"; }

.tk-icon-triangle-down:before {
  content: "\EA25"; }

.tk-icon-triangle-left:before {
  content: "\EA26"; }

.tk-icon-triangle-right:before {
  content: "\EA27"; }

.tk-icon-triangle-up:before {
  content: "\EA28"; }

.tk-icon-action-add:before {
  content: "\EA29"; }

.tk-icon-action-bookmark:before {
  content: "\EA2A"; }

.tk-icon-action-call:before {
  content: "\EA2B"; }

.tk-icon-action-cancel:before {
  content: "\EA2C"; }

.tk-icon-action-create:before {
  content: "\EA2D"; }

.tk-icon-action-delete:before {
  content: "\EA2E"; }

.tk-icon-action-download:before {
  content: "\EA2F"; }

.tk-icon-action-link-external:before {
  content: "\EA30"; }

.tk-icon-action-lock:before {
  content: "\EA31"; }

.tk-icon-action-pause:before {
  content: "\EA32"; }

.tk-icon-action-play:before, .lightbox-view-video .rs-img-2-1:before {
  content: "\EA33"; }

.tk-icon-action-print:before {
  content: "\EA34"; }

.tk-icon-action-remove:before {
  content: "\EA35"; }

.tk-icon-action-reply:before {
  content: "\EA36"; }

.tk-icon-action-search:before {
  content: "\EA37"; }

.tk-icon-action-upload:before {
  content: "\EA39"; }

.tk-icon-content-bar:before {
  content: "\EA3A"; }

.tk-icon-content-basket:before {
  content: "\EA3B"; }

.tk-icon-content-bbq:before {
  content: "\EA3C"; }

.tk-icon-content-bed:before {
  content: "\EA3D"; }

.tk-icon-content-bike:before {
  content: "\EA3E"; }

.tk-icon-content-bus:before {
  content: "\EA3F"; }

.tk-icon-content-cafe:before {
  content: "\EA40"; }

.tk-icon-content-calendar:before {
  content: "\EA41"; }

.tk-icon-content-couch:before {
  content: "\EA42"; }

.tk-icon-content-email-open:before {
  content: "\EA43"; }

.tk-icon-content-email:before {
  content: "\EA44"; }

.tk-icon-content-fitness:before {
  content: "\EA45"; }

.tk-icon-content-home:before {
  content: "\EA46"; }

.tk-icon-content-hospital:before {
  content: "\EA47"; }

.tk-icon-content-key:before {
  content: "\EA48"; }

.tk-icon-content-location:before {
  content: "\EA49"; }

.tk-icon-content-map:before {
  content: "\EA4A"; }

.tk-icon-content-music:before {
  content: "\EA4B"; }

.tk-icon-content-news:before {
  content: "\EA4C"; }

.tk-icon-content-parking:before {
  content: "\EA4D"; }

.tk-icon-content-restaurant:before {
  content: "\EA4E"; }

.tk-icon-content-rss:before {
  content: "\EA4F"; }

.tk-icon-content-shower:before {
  content: "\EA50"; }

.tk-icon-content-table-tennis:before {
  content: "\EA51"; }

.tk-icon-content-tag:before {
  content: "\EA52"; }

.tk-icon-content-train:before {
  content: "\EA53"; }

.tk-icon-content-tv:before {
  content: "\EA54"; }

.tk-icon-content-user:before {
  content: "\EA55"; }

.tk-icon-content-videogame:before {
  content: "\EA56"; }

.tk-icon-content-wc:before {
  content: "\EA57"; }

.tk-icon-content-wifi:before {
  content: "\EA58"; }

.tk-icon-device-desktop:before {
  content: "\EA59"; }

.tk-icon-device-laptop:before {
  content: "\EA5A"; }

.tk-icon-device-phone:before {
  content: "\EA5B"; }

.tk-icon-form-check-checked:before {
  content: "\EA5C"; }

.tk-icon-form-check-unchecked:before {
  content: "\EA5D"; }

.tk-icon-form-radio-checked:before {
  content: "\EA5E"; }

.tk-icon-form-radio-unchecked:before {
  content: "\EA5F"; }

.tk-icon-nav-arrow-dropdown-down:before {
  content: "\EA60"; }

.tk-icon-nav-arrow-dropdown-up:before {
  content: "\EA61"; }

.tk-icon-nav-chevron-down:before {
  content: "\EA62"; }

.tk-icon-nav-chevron-left:before {
  content: "\EA63"; }

.tk-icon-nav-chevron-right:before {
  content: "\EA64"; }

.tk-icon-nav-chevron-up:before {
  content: "\EA65"; }

.tk-icon-nav-more-horizontal:before {
  content: "\EA66"; }

.tk-icon-nav-more-vertical:before {
  content: "\EA67"; }

.tk-icon-nav-navicon:before {
  content: "\EA68"; }

.tk-icon-nav-select:before {
  content: "\EA69"; }

.tk-icon-content-vr:before, .lightbox-view-vr .rs-img-2-1:before {
  content: "\EA6A"; }

.tk-icon-device-tablet:before {
  content: "\EA6B"; }

.tk-icon-nav-chevron-left-large:before {
  content: "\EA6C"; }

.tk-icon-nav-chevron-right-large:before {
  content: "\EA6D"; }

.tk-icon-nav-chevron-down-large:before {
  content: "\EA6E"; }

.tk-icon-nav-chevron-up-large:before {
  content: "\EA6F"; }

.tk-icon-content-washing-machine:before {
  content: "\EA70"; }

.tk-icon-content-credit-card:before {
  content: "\EA71"; }

.tk-icon-content-sport:before {
  content: "\EA72"; }

.tk-icon-content-security:before {
  content: "\EA73"; }

.tk-icon-content-build:before {
  content: "\EA74"; }

.tk-icon-content-document:before {
  content: "\EA75"; }

.tk-icon-action-filter:before {
  content: "\EA76"; }

.tk-icon-action-card:before {
  content: "\EA77"; }

.tk-icon-action-list:before {
  content: "\EA78"; }

.tk-icon-action-cancel-thin:before {
  content: "\EA79"; }

.tk-icon-action-share-circle:before {
  content: "\EA7A"; }

.tk-icon-action-play-circle-outline:before {
  content: "\EA7B"; }

.tk-icon-content-announcement:before {
  content: "\EA7C"; }

.tk-icon-nav-dots-horizontal:before {
  content: "\EA80"; }

.tk-icon-content-marker:before {
  content: "\EA81"; }

.tk-icon-content-security-home:before {
  content: "\EA82"; }

.tk-icon-content-ping-pong:before {
  content: "\EA83"; }

.tk-icon-content-bank:before {
  content: "\EA84"; }

/* Hidden font text for accessibility */
.icon-font-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

/* apply a natural box layout model to all elements, but allowing components to change */
body.video-360 {
  background: #222;
  font-family: Arial, sans-serif; }
  body.video-360.no-webgl #video-fallback {
    width: 100%;
    position: relative;
    overflow: hidden; }
  body.video-360.no-webgl .video-360-wrapper {
    position: relative;
    width: 100%; }
  body.video-360.webgl .video-360-wrapper {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%; }
  body.video-360.webgl .video-notification {
    display: none; }
  body.video-360.webgl #video-fallback {
    display: none; }
  body.video-360 .video-notification {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 0; }
  body.video-360 .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #212121;
    z-index: 10000; }
  body.video-360 .video-loader,
  body.video-360 .video-loader:after {
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px; }
  body.video-360 .video-loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    border-right: 5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    border-left: 5px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: load8 1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

#video-controls,
.a-enter-vr {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 0px 10px 10px 10px;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%); }
  #video-controls button, #video-controls input,
  .a-enter-vr button,
  .a-enter-vr input {
    float: left; }
  .no-webgl #video-controls, .no-webgl
  .a-enter-vr {
    display: none; }

#play-pause,
.a-enter-vr-button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  color: white;
  display: block;
  bottom: 10px;
  left: 10px;
  width: 10%;
  max-width: 90px;
  min-width: 70px;
  height: 60px;
  padding: 0; }

.a-enter-vr-button {
  right: 10px;
  left: auto;
  display: block; }

#play-pause-big {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -75px;
  margin-top: -75px;
  border-radius: 100px;
  border: 0;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.25s ease;
  transform: scale(1);
  opacity: 1; }
  #play-pause-big:hover, #play-pause-big:focus {
    transform: scale(1.2);
    background: rgba(255, 255, 255, 0.5);
    outline: 0; }
  #play-pause-big svg {
    width: 100px !important;
    height: 100px !important; }
  .video-playing #play-pause-big {
    opacity: 0;
    display: none; }

#seek-bar {
  width: 100%;
  margin: 0; }

/* Overwrite variables */
[type='range'] {
  -webkit-appearance: none;
  margin: 6px 0;
  width: 100%; }
  [type='range']:focus {
    outline: 0; }
    [type='range']:focus::-webkit-slider-runnable-track {
      background: rgba(255, 255, 255, 0.5); }
    [type='range']:focus::-ms-fill-lower {
      background: rgba(255, 255, 255, 0.5); }
    [type='range']:focus::-ms-fill-upper {
      background: rgba(255, 255, 255, 0.5); }
  [type='range']::-webkit-slider-runnable-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    box-shadow: 0px 0px 0px transparent, 0 0 0px rgba(13, 13, 13, 0);
    background: rgba(255, 255, 255, 0.5);
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: white;
    border: 0px solid #eceff1;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    -webkit-appearance: none;
    margin-top: -3.5px; }
  [type='range']::-moz-range-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    box-shadow: 0px 0px 0px transparent, 0 0 0px rgba(13, 13, 13, 0);
    background: rgba(255, 255, 255, 0.5);
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: white;
    border: 0px solid #eceff1;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px; }
  [type='range']::-ms-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent; }
  [type='range']::-ms-fill-lower {
    box-shadow: 0px 0px 0px transparent, 0 0 0px rgba(13, 13, 13, 0);
    background: rgba(242, 242, 242, 0.5);
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-ms-fill-upper {
    box-shadow: 0px 0px 0px transparent, 0 0 0px rgba(13, 13, 13, 0);
    background: rgba(255, 255, 255, 0.5);
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: white;
    border: 0px solid #eceff1;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin-top: 0; }

/**
 *  Residence Map
 */
.marker {
  cursor: pointer;
  width: auto !important; }

.marker-toggle {
  position: absolute;
  bottom: 0;
  left: 10px; }
  .marker-toggle button {
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-right: 5px;
    background: #212121; }
    .marker-toggle button.active {
      background: #b31b1b; }

.residence-map {
  position: relative; }
  .residence-map .googlemap {
    width: 100%;
    height: 300px; }
    .residence-map .googlemap > div {
      position: absolute;
      top: 0; }
    .residence-map .googlemap h3 {
      margin: 0;
      color: #916f40; }
    .residence-map .googlemap * {
      box-shadow: none !important;
      font-size: 12px; }
    .residence-map .googlemap .marker-text {
      padding: 1px; }
      .residence-map .googlemap .marker-text p {
        margin: 0; }
    .residence-map .googlemap .close-infowindow {
      position: relative;
      right: 9px;
      top: 5px;
      cursor: pointer;
      color: #ffffff;
      z-index: 1;
      border: 0;
      text-align: right;
      width: 27px;
      float: right; }
    @media (min-width: 480px) {
      .residence-map .googlemap {
        height: 350px; } }
    @media (min-width: 768px) {
      .residence-map .googlemap {
        height: 450px; } }

/* Accomodation gallery lightbox */
[class*="lightbox-view-"] .rs-img-2-1 {
  position: relative; }

[class*="lightbox-view-"] .rs-img-2-1:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -36px;
  width: 72px;
  height: 72px;
  opacity: 0.8;
  font-size: 72px;
  color: #111111;
  z-index: 1; }

.lightbox-button {
  background: #212121;
  color: #ffffff !important;
  position: absolute;
  text-transform: uppercase;
  padding: 16px;
  bottom: 16px;
  left: 16px;
  z-index: 1; }
  .lightbox-button:hover, .lightbox-button:focus {
    text-decoration: none;
    background: #272727; }

/* Icon list -> single residence page

*/
.icon-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: top;
  justify-content: space-around; }

.icon-list-item {
  text-align: center;
  width: 120px; }
  @media (min-width: 768px) {
    .icon-list-item {
      flex: 1; } }
  .icon-list-item .tk-icon, .icon-list-item [class*="lightbox-view-"] .rs-img-2-1:before, [class*="lightbox-view-"] .icon-list-item .rs-img-2-1:before {
    font-size: 48px;
    color: #916f40; }
    @media (min-width: 768px) {
      .icon-list-item .tk-icon, .icon-list-item [class*="lightbox-view-"] .rs-img-2-1:before, [class*="lightbox-view-"] .icon-list-item .rs-img-2-1:before {
        font-size: 60px; } }

/* Card filter animation -> residence index

*/
/**
 * Animation of residence cards
 */
.card-anim {
  transform: translateX(-20px);
  opacity: 0; }
  .no-js .card-anim {
    transform: translateX(0px);
    opacity: 1; }

[class*='animate-in'] {
  transform: translateX(0px);
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  opacity: 1; }

.animate-in-1 {
  transition-delay: 0s; }

.animate-in-2 {
  transition-delay: 0.1s; }

.animate-in-3 {
  transition-delay: 0.2s; }

.animate-in-4 {
  transition-delay: 0.3s; }

.animate-in-5 {
  transition-delay: 0.4s; }

.animate-in-6 {
  transition-delay: 0.5s; }

.inverted {
  filter: invert(100%);
  -webkit-filter: invert(1); }

/* Accom iframe */
.map-frame {
  width: 100%;
  height: 500px;
  outline: 0; }

.heading-acc-rooms {
  text-align: center;
  font-family: "freight-text-pro", serif;
  line-height: 150%; }

/* CUSTOM STUFF HERE! */
@media (min-width: 480px) {
  .table-heading {
    min-width: 180px; } }

.ms-parent {
  max-width: 100%; }

@media (min-width: 768px) {
  .table-w-10 {
    width: 10%; }
  .table-w-15 {
    width: 15%; }
  .table-w-20 {
    width: 20%; }
  .table-w-25 {
    width: 25%; }
  .table-w-30 {
    width: 30%; }
  .table-w-40 {
    width: 40%; } }

/**
 * Swiper Multiple items (see Accommodation: Single residence page)
 */
.swiper-multi {
  background: #272727;
  padding: 0 45px;
  overflow: initial; }
  .swiper-multi .owl-item {
    text-align: center; }
    .swiper-multi .owl-item p {
      max-width: 265px;
      margin: 0 auto;
      font-size: 16px;
      color: #ffffff;
      padding: 0px 10px 20px 10px; }
    .swiper-multi .owl-item .item {
      padding: 5px; }
    .swiper-multi .owl-item .tk-icon, .swiper-multi .owl-item [class*="lightbox-view-"] .rs-img-2-1:before, [class*="lightbox-view-"] .swiper-multi .owl-item .rs-img-2-1:before {
      color: white;
      font-size: 60px;
      margin-top: 12px;
      display: block; }
  .swiper-multi .owl-buttons div {
    top: 25px; }
  .swiper-multi .owl-buttons .owl-prev {
    border: 0;
    height: 100%;
    left: 0;
    top: 0; }
    @media (min-width: 1340px) {
      .swiper-multi .owl-buttons .owl-prev {
        top: 25px; } }
  .swiper-multi .owl-buttons .owl-next {
    border: 0;
    height: 100%;
    right: 0;
    top: 0; }
    @media (min-width: 1340px) {
      .swiper-multi .owl-buttons .owl-next {
        top: 25px; } }
